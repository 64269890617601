<template>
  <div class="addMain">
    <h3>公告标题</h3>
    <div class="addInput"><el-input v-model="form.header" placeholder="请输入公告标题"></el-input></div>
    <h3>公告内容</h3>
    <div class="addInput"><quill-editor class="mb-5" v-model="form.text" ref="quill"></quill-editor></div>
    <div class="btnBox">
      <el-button class="btn plain" @click="$router.go(-1)">返回</el-button>
      <el-button class="btn" @click="submit(id)">发布</el-button>
    </div>
  </div>
</template>

<script>
import QuillEditor from '@/components/Editor'
import { addAffiche, editAffiche, getAfficheDetail } from '@/api/team-info'
export default {
  name: 'add-annouce',
  components: {
    'quill-editor': QuillEditor
  },
  data () {
    return {
      form: {
        header: '',
        text: ''
      },
      id: ''
    }
  },
  mounted () {
    this.id = this.$route.query.id
    if (this.id) {
      this.init(this.id)
    }
  },
  methods: {
    init (id) {
      const data = {
        aid: id
      }
      getAfficheDetail(data).then(res => {
        if (res.code === 0) {
          this.form = res.data
          this.$refs.quill.quill.container.firstChild.innerHTML = this.form.text
        }
      })
    },
    submit () {
      if (!this.form.header) {
        this.$message.error('请输入公告标题')
        return
      }
      if (!this.form.text) {
        this.$message.error('请输入公告内容')
        return
      }
      const data = {
        teamid: 0,
        aid: Number(this.id),
        header: this.form.header,
        text: this.form.text
      }
      if (this.id) {
        editAffiche(data).then(res => {
          this.$message.success('编辑成功')
          this.$router.go(-1)
        })
      } else {
        addAffiche(data).then(res => {
          this.$message.success('发布成功')
          this.$router.go(-1)
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.addMain{
  h3{
    font-size: 24px;
    font-weight: bold;
    color: #3D3E3E;
    margin: 34px 0;
  }
  .addInput{
    margin-left: 40px;
    /deep/.el-input__inner{
      height: 66px;
      border-radius: 2px;
      border: 1px solid #d9d9d9;
    }
    /deep/.ql-container{
      min-height: 200px;
      .ql-editor.ql-blank::before{
        font-style: normal;
        font-size: 14px;
        color: #c3c3c3;
      }
    }
    /deep/.ql-snow{
      border-color: #d9d9d9;
    }
  }
  .btnBox{
    margin: 50px 0;
    margin-left: 40px;
    .btn{
      width: 96px;
      height: 40px;
      background: #3775F6;
      opacity: 1;
      border-radius: 2px;
      color: #fff;
    }
    .plain{
      background: #fff;
      color: #3775F6;
      border: 1px solid #3775F6;
    }
  }
}

</style>
